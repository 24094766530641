import {
  replaceableEntityNumber,
  replacedEntityNumber,
} from '@app/appConstants';
import {
  AddressComponent,
  GoogleAddressResult,
} from '@app/model/interfaces/common';
import { UserDetails } from '@app/model/interfaces/settings';
import * as moment from 'moment';

export const numberOnly = (event: KeyboardEvent): boolean =>
  Number(event.key) ? true : event.key === '0' ? true : false;

export const decimalNumberOnly = (event: KeyboardEvent): boolean => {
  return Number(event.key)
    ? true
    : event.key === '0' || event.key === '.'
    ? true
    : false;
};

export const compare =
  (prop: string, type: string = 'string') =>
  (a: any, b: any): number => {
    if (prop) {
      if (type === 'string') {
        if (a[prop].toLowerCase() < b[prop].toLowerCase()) {
          return -1;
        }
        if (a[prop].toLowerCase() > b[prop].toLowerCase()) {
          return 1;
        }
        return 0;
      } else {
        if (a[prop] < b[prop]) {
          return -1;
        }
        if (a[prop] > b[prop]) {
          return 1;
        }
        return 0;
      }
    } else {
      if (type === 'string') {
        if (a.toLowerCase() < b.toLowerCase()) {
          return -1;
        }
        if (a.toLowerCase() > b.toLowerCase()) {
          return 1;
        }
        return 0;
      } else {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      }
    }
  };

export const compareWithOrder =
  (prop: string, sortOrder: string) =>
  (a: any, b: any): number => {
    if (sortOrder === 'ASC') {
      if (a[prop] < b[prop]) {
        return -1;
      }
      if (a[prop] > b[prop]) {
        return 1;
      }
      return 0;
    }
    if (sortOrder === 'DESC') {
      if (a[prop] < b[prop]) {
        return 1;
      }
      if (a[prop] > b[prop]) {
        return -1;
      }
      return 0;
    } else {
      return 0;
    }
  };

export const updatePercentageValue = (data: any) => {
  data = data.map((dt: any) => {
    return {
      ...dt,
      percentage: fixedDecimal(dt.percentage),
    };
  });
  let addedValue = 0;
  data.forEach((dt: any, index: number) => {
    if (index !== 0) {
      addedValue += dt.percentage;
    }
  });
  if (data.length) {
    data[0].percentage = fixedDecimal(100 - addedValue);
  }
  return data;
};

export const fixedDecimal = (val: number): number => Math.round(val * 10) / 10;

export const fixedEncodeURIComponent = (str: string): string =>
  encodeURIComponent(str).replace(
    /[!'()*]/g,
    (c) => '%' + c.charCodeAt(0).toString(16).toUpperCase()
  );

export const getBankCountryNLanguage = (language: string, index = 0): string =>
  language.split('-')[index].toUpperCase();

export const extractCityState = (result: GoogleAddressResult): any => {
  const flatMap: any = {};
  result.address_components.forEach((component: AddressComponent) => {
    flatMap[component.types[0]] = {
      long_name: component.long_name,
      short_name: component.short_name,
    };
  });
  let city: any = flatMap.neighborhood
    ? flatMap.neighborhood
    : flatMap.sublocality
    ? flatMap.sublocality
    : flatMap.locality
    ? flatMap.locality
    : flatMap.administrative_area_level_3
    ? flatMap.administrative_area_level_3
    : flatMap.administrative_area_level_2
    ? flatMap.administrative_area_level_2
    : '';

  if (city) {
    city = city.long_name;
  }

  const addressInfo = result.formatted_address.split(',');
  if (addressInfo.length > 1) {
    city = addressInfo[0];
  }
  let country = flatMap.country ? flatMap.country.short_name : '';
  let state = '';
  const locality = flatMap.administrative_area_level_1 || flatMap.locality;
  if (locality) {
    state = country == 'PR' ? 'Puerto Rico' : locality.long_name;
  }
  if (!state) {
    city = '';
    country = '';
  }
  if (
    result.postcode_localities &&
    !result.postcode_localities.includes(city)
  ) {
    result.postcode_localities.push(city);
    result.postcode_localities.sort();
  }
  const isValidCountry = validateCountry(country);
  const isMultiCityAvailable =
    isValidCountry && result.postcode_localities?.length > 1;
  const multiCityArray =
    isValidCountry && result.postcode_localities
      ? [...result.postcode_localities]
      : [];
  if (isMultiCityAvailable) {
    if (!result.postcode_localities.some((cy: string) => cy == city)) {
      city = result.postcode_localities[0];
    }
  }
  return {
    city,
    state,
    country,
    isMultiCityAvailable,
    multiCityArray,
    isValidCountry,
    postcodeLocalities: result.postcode_localities,
  };
};

export const extractAddress = (components: any) => {
  const location = {
    address: '',
    city: '',
    state: '',
    state_sn: '',
    country: '',
    zipcode: '',
  };
  components.forEach((component: any) => {
    component.types.forEach((type: string) => {
      if (type === 'route') {
        location.address += component.short_name;
      }
      if (type === 'street_number') {
        location.address += component.short_name;
      }
      if (type === 'locality') {
        location.city = component.short_name;
      }
      if (type === 'administrative_area_level_1') {
        location.state = component.long_name;
        location.state_sn = component.short_name;
      }
      if (type === 'country') {
        location.country = component.short_name;
      }
      if (type === 'postal_code') {
        location.zipcode = component.short_name;
      }
    });
  });
  return location;
};

export const validateCountry = (country: string): boolean =>
  ['US', 'CA', 'PR'].includes(country);
export const extractCityStateFromMultipleResult = (result: []): any => {
  let address = {};
  result.forEach((element: GoogleAddressResult) => {
    const addressDetails = extractCityState(element);
    if (validateCountry(addressDetails.country)) {
      address = addressDetails;
    }
  });
  return address;
};

export const arraysorted = (arr: any, type: string, revert = false) => {
  if (!revert) {
    return arr.sort((a: any, b: any) =>
      a[type].toLowerCase() === b[type].toLowerCase()
        ? 0
        : a[type].toLowerCase() > b[type].toLowerCase()
        ? 1
        : -1
    );
  }
  return arr.sort((a: any, b: any) =>
    a[type].toLowerCase() === b[type].toLowerCase()
      ? 0
      : a[type].toLowerCase() < b[type].toLowerCase()
      ? 1
      : -1
  );
};

export const arraysortedByLength = (arr: any, type: string) =>
  arr.sort((a: any, b: any) =>
    (a[type].length === b[type].length ? 0 : a[type].length) > b[type].length
      ? 1
      : -1
  );

export const replaceStringVal = (
  message: string,
  name: string,
  replacedWith: string
): string => message.replace(replacedWith, name);

export const replaceAsiicCharacters = (paragraph: string) => {
  replaceableEntityNumber.forEach((item: string, index: number) => {
    if (paragraph.indexOf(item) > -1) {
      paragraph = paragraph.split(item).join(replacedEntityNumber[index]);
    }
  });
  return paragraph;
};

export const getStringCapital = (text: string) =>
  `${text.charAt(0).toUpperCase()}${text.slice(1).toLowerCase()}`;

export const convertDateToEst = (date: string, removeGMT = false) =>
  !removeGMT
    ? moment(new Date(date)).format('YYYY-MM-DD HH:mm:ss.SSS')
    : date.split('+')[0];
export const pickOneDate = (item: any) =>
  item.date
    ? convertDateToEst(item.date)
    : item.postedDate
    ? convertDateToEst(item.postedDate)
    : convertDateToEst(item.createdAt);

export const updateQuickTextWithTag = (
  cursorPos: number,
  textContent: string,
  item: string
) => {
  textContent = replaceAsiicCharacters(textContent);

  return `${textContent.slice(0, cursorPos)} #${item} ${textContent.slice(
    cursorPos
  )}`;
};

export const addHash = (tagName: string) => {
  return tagName.includes('#') ? tagName : `#${tagName}`;
};

export const node_walk = (node: any, func: any) => {
  var result = func(node);
  for (
    node = node.firstChild;
    result !== false && node;
    node = node.nextSibling
  )
    result = node_walk(node, func);
  return result;
};

export const getCaretPosition = (elem: any) => {
  let cursorPos = -1;
  const sel: any = window.getSelection();
  var cum_length = [0, 0];

  if (sel.baseNode && sel.baseNode.parentNode.nodeName === 'SPAN') {
    return -1;
  }

  if (sel.anchorNode == elem) cum_length = [sel.anchorOffset, sel.extentOffset];
  else {
    var nodes_to_find = [sel.anchorNode, sel.extentNode];
    if (!elem.contains(sel.anchorNode) || !elem.contains(sel.extentNode)) {
    } else {
      const found: any = [0, 0];
      var i;
      node_walk(elem, (node: any): any => {
        for (i = 0; i < 2; i++) {
          if (node == nodes_to_find[i]) {
            found[i] = true;
            if (found[i == 0 ? 1 : 0]) return false; // all done
          }
        }

        if (node.textContent && !node.firstChild) {
          for (i = 0; i < 2; i++) {
            if (!found[i]) cum_length[i] += node.textContent.length;
          }
        }
      });
      cum_length[0] += sel.anchorOffset;
      cum_length[1] += sel.extentOffset;
    }
  }
  return cum_length[1];
};

export const convertToLocaleUTC = (
  date: string,
  time: string,
  period: string
): Date => {
  let hour = time.split(':')[0];
  const min = time.split(':')[1];
  if (period === 'PM' && Number(hour) < 12) {
    hour = String(Number(hour) + 12);
  }
  if (period === 'AM' && Number(hour) == 12) {
    hour = '00';
  }
  return new Date(`${date}T${hour}:${min}:00`);
};

export const formatPhoneNumber = (phoneNumber: string = ''): string => {
  let newVal = '';
  phoneNumber = phoneNumber ? phoneNumber.replace(/[^0-9]*/g, '') : '';
  phoneNumber = phoneNumber
    ? phoneNumber.substring(phoneNumber.length - 10)
    : '';
  if (phoneNumber.length == 0) {
    newVal = '';
  } else if (phoneNumber.length <= 3) {
    newVal = phoneNumber.replace(/^(\d{0,3})/, '($1');
  } else if (phoneNumber.length <= 6) {
    newVal = phoneNumber.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
  } else {
    newVal = phoneNumber.replace(/^(\d{0,3})(\d{0,3})(.*)/, '($1) $2-$3');
  }
  return newVal;
};

export const formatDate = (value: string, format = 'MM/DD/YYYY') => {
  return value ? moment(value).format(format) : '';
};

export const removeSpecialCharPhoneNumber = (phoneNumber: string): string => {
  return phoneNumber ? phoneNumber.replace(/[^0-9]*/g, '') : '';
};

export const insertSignature = (profileData: UserDetails) => {
  if (profileData) {
    return `
    <div style="font-family:arial" class="emailSignature>
    <div style="font-size: 16px; font-weight: bold;">
    ${profileData.firstName} ${profileData.lastName}
  </div>
  <div style="font-size: 14px; margin-bottom: 18px">
    Clover Business Consultant<br />
    Office: ${formatPhoneNumber(profileData.phone)}<br />
    <a href="mailto:${profileData.email}" style="font-size: 14px">${
      profileData.email
    }</a>
  </div>
  <div style="height:37px"><img width="150" src="https://int.catalyst.clover.com/assets/images/cloverLogoEmail.png" /></div>
  <div style="font-size: 14px; margin-bottom: 25px">
    <div><span style="font-weight: bold">Clover</span> from Fiserv</div>
    <div style="font-weight: bold; color: #208801">
      Helping Small Businesses Thrive
    </div>
    <div>
      <a href="https://www.clover.com/" style="font-size: 14px;" target="_blank">Clover</a> |
      <a href="https://www.careers.fiserv.com/"  style="font-size: 14px;"target="_blank">Join Our Team</a> |
      <a href="https://twitter.com/clovercommerce"  style="font-size: 14px;"target="_blank">Twitter</a> |
      <a href="https://www.linkedin.com/company/clovernetwork/"  style="font-size: 14px;"target="_blank">LinkedIn</a> |
      <a href="https://www.facebook.com/clovercommerce/"  style="font-size: 14px;"target="_blank">Facebook</a> |
      <a href="https://www.instagram.com/clovercommerce/?hl=en"  style="font-size: 14px;"target="_blank">Instagram</a>
    </div>
  </div>
  <div style="font-size: 12px;">
    © ${new Date().getFullYear()} Clover Network, Inc. The Clover name and logo are registered
    trademarks owned by Clover Network, Inc. and are registered or used in the
    U.S. and many foreign countries. <br />
    <a href="https://www.clover.com/privacy-policy" style="font-size: 12px; target="_blank">Privacy Notice</a>
  </div>
    </div>
    `;
  } else {
    return '';
  }
};

export const getFileType = (type: string) => {
  switch (type) {
    case 'image/png':
    case 'data:image/png':
      return 'png';
    case 'application/pdf':
    case 'data:application/pdf':
      return 'pdf';
    case 'image/jpeg':
    case 'data:image/jpeg':
      return 'jpg';
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'word';
    case 'application/msword':
      return 'word';
    default:
      return '';
  }
};

export const formatBytes = (bytes: any, decimals = 2) => {
  if (!+bytes) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const getDecimal = (value1: number, value2: number) => {
  let decimal = 1;
  if (Math.abs(value1 - value2) < 100) {
    decimal = 5;
  } else if (Math.abs(value1 - value2) < 1000) {
    decimal = 4;
  } else if (Math.abs(value1 - value2) < 10000) {
    decimal = 3;
  } else {
    decimal = 2;
  }
  return decimal;
};

export const getNumberOfDays = (value: number) => {
  const YEAR = 365,
    MONTH = 30,
    WEEK = 7;
  let year, months, week, days;

  year = value >= YEAR ? Math.floor(value / YEAR) : 0;
  value = year ? value - year * YEAR : value;

  months = value >= MONTH ? Math.floor((value % YEAR) / MONTH) : 0;
  value = months ? value - months * MONTH : value;

  week = value >= WEEK ? Math.floor((value % YEAR) / WEEK) : 0;
  value = week ? value - week * WEEK : value;

  days = value < WEEK ? Math.floor((value % YEAR) % WEEK) : 0;
  return { year, months, week, days };
};

export const getEncodingURL = (imagePath: string | undefined) => {
  if (imagePath) {
    return `${
      window.location.origin
    }/api/referralServices/v1/users/${encodeURIComponent(
      imagePath
    )}/profileImage`;
  }
  return '';
};

export const groupArraybyKey = (array: any) =>
  array.reduce((group: any, textItem: any) => {
    let { targetEntityType, activityType, createdAt } = textItem;
    targetEntityType = targetEntityType || `${activityType}_${createdAt}`;
    group[targetEntityType] = group[targetEntityType] ?? [];
    group[targetEntityType].push(textItem);
    return group;
  }, {});

export const updateMultipleStrings = (template: string, data: any) =>
  template.replace(
    /\$count\$|\$sequenceName\$|\$module\$/gi,
    (matched) => data[matched]
  );

export const updateTimeDifference = (date: string) => {
  const diff = moment(new Date()).diff(date, 'days');
  if (diff === 0 && new Date(date).getDate() === new Date().getDate()) {
    return moment(date).format('h:mm a');
  } else {
    return moment(date).format('MMM D, y, h:mm a');
  }
};

export const sortactivtiesbyDate = (arr: any, text = true) => {
  if (text) {
    return arr.sort((a: any, b: any) => {
      if (a.date && b.date) {
        return a.date === b.date ? 0 : a.date > b.date ? 1 : -1;
      } else if (a.postedDate && b.postedDate) {
        return a.postedDate === b.postedDate
          ? 0
          : a.postedDate > b.postedDate
          ? 1
          : -1;
      } else {
        return a.createdAt === b.createdAt
          ? 0
          : a.createdAt > b.createdAt
          ? 1
          : -1;
      }
    });
  }
  return arr.sort((a: any, b: any) => {
    if (a.date && b.date) {
      return a.date === b.date ? 0 : a.date < b.date ? 1 : -1;
    } else if (a.postedDate && b.postedDate) {
      return a.postedDate === b.postedDate
        ? 0
        : a.postedDate < b.postedDate
        ? 1
        : -1;
    } else {
      return a.createdAt === b.createdAt
        ? 0
        : a.createdAt < b.createdAt
        ? 1
        : -1;
    }
  });
};
export const truncate = (value: string, length: number = 15) =>
  value && value.length > length ? `${value.substring(0, length)}...` : value;
export const sortByDate = (arr: any, prop: string, orderBy = 'desc') => {
  return arr.sort((a: any, b: any) => {
    return a[prop] === b[prop]
      ? 0
      : (orderBy == 'asc' ? a[prop] > b[prop] : a[prop] < b[prop])
      ? 1
      : -1;
  });
};

export const getLocalTime = (merchantDaylightSavingsTimeZone: string) => {
  const time = new Date(
    new Date().toLocaleString('en-US', {
      timeZone: 'UTC',
    })
  );

  const localTime = moment(time)
    .add(getTimeDifference(merchantDaylightSavingsTimeZone), 'h')
    .format('hh:mm:ss A');
  return {
    time,
    localTime,
  };
};

export const getTimeDifference = (merchantDaylightSavingsTimeZone: string) => {
  switch (merchantDaylightSavingsTimeZone) {
    case 'EST':
      return -5;
    case 'CST':
      return -6;
    case 'MST':
      return -7;
    case 'PST':
      return -8;
    case 'AKST':
      return -9;
    case 'HST':
      return -10;
    case 'CDT':
      return -5;
    case 'MDT':
      return -6;
    case 'PDT':
      return -7;
    case 'AKDT':
      return -8;
    case 'HDT':
      return -9;
    default:
      return -4;
  }
};

export const getDatePos = (date: string) => {
  const value = Number(date);
  const lastDigit = value % 10;
  switch (lastDigit) {
    case 1:
      return date + 'st';
    case 2:
      return date + 'nd';
    case 3:
      return date + 'rd';
    default:
      return date + 'th';
  }
};

export const getUTCbyDate = (date: string) =>
  moment.utc(new Date(`${date}:00:00:00`)).format('YYYY-MM-DDTHH:mm:ss');
