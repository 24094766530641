import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ActivateResponse,
  BranchGridPayload,
  BranchHierarchyDivisions,
  BranchHierarchyList,
  BranchSetup,
  CommonPostCallResponse,
  NotificationData,
  NotificationSubscription,
  QuickTextDetails,
  QuickTextDetailsResponse,
  QuickTextResponse,
  UserDetails,
  UserDetailsPayload,
  UserDetailsResponse,
} from '@app/model/interfaces/settings';
import {
  BankDomainList,
  Branch,
  BranchResponse,
  BusinessConsultant,
  BusinessConsultantsResponse,
  DoaminREstrictions,
  Region,
  RegionsResponse,
  Role,
  RolesResponse,
  UserPayload,
  UsersListResponse,
  userPrivilege,
} from '@app/model/interfaces/user-management';

import { AutoSuggestResponse } from '@app/model/interfaces/sales';
import { BankInfoService } from '@app/shared/services/bank-info.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private userPayload: UserPayload = {
    searchBy: '',
    pageSize: 5,
    pageNum: 0,
    sortBy: 'firstName',
    sortOrder: 'ASC',
    status: 'ACTIVE',
    filterParams: '',
  };

  private userRoles!: Role[];
  private userPrivilege!: userPrivilege[];
  private isLoggedInUserInternal = false;
  private domainsData?: BankDomainList;
  private businessConsultant!: BusinessConsultant[];
  private isLoggedInUser = false;
  private usersProfile: any = {};

  setUsersProfile = (userId: string, usersProfile: any) => {
    this.usersProfile[userId] = usersProfile;
  };

  getUsersProfile = () => this.usersProfile;

  setUserRoles = (userRoles: Role[]) => {
    this.userRoles = [...userRoles];
  };

  getUserRoles = () => this.userRoles;
  setUserPrivilege = (userPrivilege: userPrivilege[]) => {
    this.userPrivilege = [...userPrivilege];
  };

  getUserPrivilege = () => this.userPrivilege;

  getLoggedInUserType = () => this.isLoggedInUser;

  setLoggedInUserType = (userDetails: UserDetails) =>
    (this.isLoggedInUser = userDetails.requiresBranch
      ? userDetails.requiresBranch
      : false);

  setIsLoggedInUserInternal = (role: boolean) =>
    (this.isLoggedInUserInternal = role);

  getIsLoggedInUserInternal = () => this.isLoggedInUserInternal;

  getBusinessConsultant(): BusinessConsultant[] {
    return this.businessConsultant;
  }

  setBusinessConsultant(bc: BusinessConsultant[]): void {
    this.businessConsultant = bc;
  }

  setDomainData(domainData: BankDomainList): void {
    this.domainsData = domainData;
  }
  getDomainData(): BankDomainList {
    return this.domainsData ? this.domainsData : ({} as BankDomainList);
  }
  constructor(private http: HttpClient, private bankService: BankInfoService) {}

  getUsersList(
    payload: UserPayload,
    bankId: number
  ): Observable<UsersListResponse> {
    const params = new HttpParams({
      fromObject: JSON.parse(JSON.stringify(payload)),
    });
    return this.http
      .get(`${environment.referralServices}/v1/bank/${bankId}/users/`, {
        params,
      })
      .pipe(
        catchError(this.handleError),
        tap((response: UsersListResponse) => {})
      );
  }

  getDomainRestrictions(bankId: number): Observable<BankDomainList> {
    return this.http
      .get<BankDomainList>(
        `${environment.referralServices}/v1/bank/${bankId}/users/domainRestrictions/`
      )
      .pipe(
        catchError(this.handleError),
        tap((response: BankDomainList) => {})
      );
  }

  setDomainRestrictions = (payload: DoaminREstrictions): Observable<any> => {
    const bankId = this.bankService.getBankId();
    const postUrl = `${environment.referralServices}/v1/bank/${bankId}/users/domainRestrictions/`;
    return this.http.post(postUrl, payload).pipe(
      catchError(this.handleError),
      tap(() => {})
    );
  };

  getBranchFullList(bankId: number): Observable<BranchSetup> {
    return this.http
      .get<BranchSetup>(
        `${environment.referralServices}/v1/bank/${bankId}/setup/`
      )
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }

  getBranches(bankId: number): Observable<Branch[]> {
    return this.http
      .get<BranchResponse>(
        `${environment.referralServices}/v1/bank/${bankId}/branches/`
      )
      .pipe(
        map((response) => response.branches || []),
        catchError(this.handleError)
      );
  }

  getBCBranches(bankId: number): Observable<BusinessConsultant[]> {
    return this.http
      .get<BusinessConsultantsResponse>(
        `${environment.referralServices}/v1/bank/${bankId}/businessconsultant/`
      )
      .pipe(
        map((response) => response.businessConsultants || []),
        catchError(this.handleError)
      );
  }

  getBusinessConsultantBranches(
    bankId: number
  ): Observable<BusinessConsultantsResponse> {
    return this.http
      .get<BusinessConsultantsResponse>(
        `${environment.referralServices}/v1/bank/${bankId}/businessconsultant/`
      )
      .pipe(catchError(this.handleError));
  }

  getRegions(bankId: number): Observable<Region[]> {
    return this.http
      .get<RegionsResponse>(
        `${environment.referralServices}/v1/bank/${bankId}/regions/`
      )
      .pipe(
        map((response) => response.regions || []),
        catchError(this.handleError)
      );
  }

  getRoles(): Observable<RolesResponse> {
    return this.http
      .get(`${environment.referralServices}/v1/users/roles/`)
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }

  getUserDetail(userId: string): Observable<UserDetailsResponse> {
    userId = encodeURIComponent(userId);
    return this.http
      .get(`${environment.referralServices}/v1/users/${userId}/`)
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }
  getMappingSalesForce(userId: string, salesForceId: string): Observable<any> {
    return this.http
      .get(
        `${environment.salesRegimeServices}/v1/user/settings/getUserSalesforceMapping/${userId}/${salesForceId}`
      )
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }
  updateUserProfile(
    payload: UserDetailsPayload,
    userId: string
  ): Observable<UserDetailsResponse> {
    let url = environment.referralServices + '/v1/users/';
    if (userId) {
      url = url + userId + '/';
    }
    return this.http.post(url, payload).pipe(
      catchError(this.handleError),
      tap(() => {})
    );
  }

  getUserDetails(): Observable<UserDetailsResponse> {
    return this.http
      .get(`${environment.referralServices}/v1/users/current/`)
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }

  updateUserDetails(payload: UserDetails): Observable<UserDetailsResponse> {
    return this.http
      .post(`${environment.referralServices}/v1/users/current/`, payload)
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }

  createOrEditBranch(
    bankId: number,
    payload: BranchGridPayload
  ): Observable<CommonPostCallResponse> {
    return this.http
      .post(
        `${environment.referralServices}/v1/bank/${bankId}/branches/`,
        payload
      )
      .pipe(
        catchError(this.handleError),
        tap((response: CommonPostCallResponse) => {})
      );
  }

  activeDeactiveUser(
    userId: string,
    status: string
  ): Observable<ActivateResponse> {
    return this.http
      .post(`${environment.referralServices}/v1/users/${status}/${userId}/`, {})
      .pipe(
        catchError(this.handleError),
        tap((response: ActivateResponse) => {})
      );
  }

  getDivisionAll(): Observable<BranchHierarchyDivisions> {
    const bankId = this.bankService.getBankId();
    return this.http
      .get(`${environment.referralServices}/v1/banks/${bankId}/hierarchy/`)
      .pipe(
        catchError(this.handleError),
        tap((response: BranchHierarchyDivisions) => {})
      );
  }
  savedBranchlist(
    loadhierarchy: BranchHierarchyList
  ): Observable<CommonPostCallResponse> {
    const bankId = this.bankService.getBankId();
    return this.http
      .post(
        `${environment.referralServices}/v1/banks/${bankId}/hierarchy/`,
        loadhierarchy
      )
      .pipe(
        catchError(this.handleError),
        tap((response: CommonPostCallResponse) => {})
      );
  }

  getAllQuickTexts = (): Observable<QuickTextDetailsResponse> => {
    const url = `${environment.salesRegimeServices}/v1/quickTexts`;
    return this.http.get(url).pipe(
      tap((response: QuickTextResponse) => {}),
      catchError(this.handleError)
    );
  };

  createQuickText = (payload: QuickTextDetails): Observable<any> => {
    const url = payload.id
      ? `${environment.salesRegimeServices}/v1/quickTexts/${payload.id}`
      : `${environment.salesRegimeServices}/v1/quickTexts`;

    return this.http.post(url, payload).pipe(
      tap((response: QuickTextResponse) => {}),
      catchError(this.handleError)
    );
  };
  revokeUserAccess = (): Observable<any> => {
    const url = `${environment.salesRegimeServices}/v1/user/msal/deregister`;
    return this.http.post(url, {}).pipe(
      tap((response: any) => {}),
      catchError(this.handleError)
    );
  };

  deleteQuickText = (id: number): Observable<any> => {
    const url = `${environment.salesRegimeServices}/v1/quickTexts/${id}/delete`;
    return this.http.post(url, id).pipe(
      tap((response: QuickTextResponse) => {}),
      catchError(this.handleError)
    );
  };

  getSalesForceUserIdOptions(
    bankId: number,
    keyword: string | number
  ): Observable<AutoSuggestResponse> {
    return this.http
      .get<AutoSuggestResponse>(
        `${environment.referralServices}/v1/bank/${bankId}/bankReferral/fields/138/options/${keyword}/`
      )
      .pipe(
        tap((response: AutoSuggestResponse) => {}),
        catchError(this.handleError)
      );
  }

  getNotifications = (): Observable<NotificationData> => {
    const url = `${environment.salesRegimeServices}/v1/user/notifications`;
    return this.http.get(url).pipe(
      tap((response: any) => {}),
      catchError(this.handleError)
    );
  };

  getSubscription = (): Observable<NotificationSubscription> => {
    const url = `${environment.salesRegimeServices}/v1/notification/subscription`;
    return this.http.get(url).pipe(
      tap((response: any) => {}),
      catchError(this.handleError)
    );
  };

  updateSubscription = (
    payload: NotificationSubscription[]
  ): Observable<any> => {
    const url = `${environment.salesRegimeServices}/v1/notification/subscription`;
    return this.http.post(url, payload).pipe(
      tap((response: any) => {}),
      catchError(this.handleError)
    );
  };

  handleError(error: HttpErrorResponse): Observable<any> {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
