const commonLocaleString = {
  confirm: 'shared.common.text.confirm',
  cancel: 'common.button.text.cancel',
  somethingApiError: 'something.wrong',
};

export const callDialogStaticContent = {
  ...commonLocaleString,
  phoneConnectError: 'phoneConnectError',
  callFeatureHeading: 'callFeatureHeading',
  callFeatureMessage: 'callFeatureMessage',
};

export const leadReminderStaticContent = {
  somethingApiError: 'something.wrong',
  successfullyCreated: 'reminder.successfully.created',
  successfullyUpdated: 'shared.common.text.successfully.updated',
  updateAsCompleted: 'reminder.sucessfully.updated.completed',
  successfullyDeleted: 'reminder.successfully.deleted',
  noCompletedReminders: 'no.completed.reminders'
};

export const microsoftAuthStaticContent = {
  somthingWentWrong: 'something.wrong',
  declinedMessage: 'shared.microsoft.declined.message',
};

export const scheduleCallBackStaticContent = {
  somethingApiError: 'something.wrong',
  successfullyCreated: 'reminder.successfully.created',
};

export const notificationDrawerStaticContent = {
  concurrentNewSessionMessage: 'concurrentNewSessionMessage',
  conCurrentExistingSessionMessage: 'conCurrentExistingSessionMessage',
};

export const sharedUploadStaticContent = {
  uploaFileInfomsg: 'upload.file.select.info',
  uploaFileOrJustificationInfomsg: 'upload.file.select.or.justification.info',
  uploaCategoryInfomsg: 'upload.file.category.info',
};

export const prospectingStaticContent = {
  ...commonLocaleString,
  reminderMarkasCompleted: 'agenda.reminder.markas.completed',
  sequenceNoAvailable: 'lead.no.sequence',
  sequenceAssignConfirm: 'lead.assign.confirm',
  sequenceAssignConfirmMessage: 'lead.assign.confirm.message',
  success: 'agenda.prospecting.detail.success',
  agendaTaskErrorTitle: 'agenda.prospecting.task.errorTitle',
  prospectingNoTaskAvailable: 'agenda.prospectingNoTaksAvailable',
  agendaTaskErrorEmailMessage: 'agenda.prospecting.task.errorEmail',
  agendaTaskErrorTextMessage: 'agenda.prospecting.task.errorText',
  mailsSuccess: 'agenda.prospecting.detail.message',
  textSuccess: 'agenda.prospecting.detail.text.sent.successfully',
  markedAsSuccess: 'agenda.prospecting.detail.marked.as.success',
  statusUpdateSuccess: 'prospects.status.success.message',
  agendaSkipped: 'agenda.task.skipped',
  completedManually: 'agenda.common.completed.manually',
  agendaTaskRemoved: 'agenda.task.remove.from.agenda',
  marksAsComplted: 'agenda.task.mark.as.completed',
  visitTaskUpdated: 'agenda.visit.task.updated',
  visitTaskMetMerchant: 'agenda.visit.task.met.merchant',
  visitTaskUnableToMeet: 'agenda.visit.task.unable.to.meet',
  calendarLinkHeading: 'calendarLink.popup.heading',
  calendarLinkMessage: 'calendarLink.popup.message',
  updatingTask: 'text.updating.task',
  sendingEmail: 'text.sending.email',
  sendingText: 'text.sending.text',
  deletingReminder: 'text.deleting.reminder',
  callInProgress: 'call.inprogress',
  reminderDeleted: 'agenda.remider.deleted',
  noUpcomingReminder: 'reminder.noUpcoming.reminder',
  delete: 'prospects.yourList.deleteListButton',
  deleteMessage: 'prospect.yourlist.delete.message',
  addedProsectToListMessage: "prospect.yourlist.add.prospect.to.list.message",
  successTitle: "upload.referral.sheet.dialog.success",
  removeProspectFromListMessage: 'prospect.remove.from.list.message',
  addAdminSuccessMessage: 'yourlist.add.admin.success.message',
  addContributorSuccessMessage: 'yourlist.add.contributor.success.message',
  removeAdminMessage: 'yourlist.remove.admin.success.message',
  removeContributorMessage: 'yourlist.remove.contributor.success.message',
  downloadProspectDataMessage: 'prospect.data.downloaded.successfully',
  downloadListDataMessage: 'yourlist.prospect.data.downloaded.successfully'
};

export const callLogStaticContent = {
  successUpdated: 'shared.common.text.successfully.updated',
};
export const sendMailStaticContent = {
  ...commonLocaleString,
  calendarLinkHeading: 'calendarLink.popup.heading',
  calendarLinkMessage: 'calendarLink.popup.message',
  sendingEmail: 'text.sending.email',
  isMaxSize: 'text.hasAttachmemts.maxSizeError',
};

export const reminderManagementStaticContent = {
  ...commonLocaleString,
  noUpcomingReminder: 'reminder.noUpcoming.reminder',
  updateAsCompleted: 'reminder.sucessfully.updated.completed',
};

export const realTimeStaticContent = {
  ...commonLocaleString,
  leadGenesysTitle: 'lead.newLead.genesysTitle',
  leadGenesysBody: 'lead.newLead.genesysBody',
  leadReassignmentTitle: 'lead.newLead.assignmentTitle',
  leadReassignmentBody: 'lead.newLead.assignmentBody',
  confirm: 'shared.notification.button',
};

export const textAcitivityStaticContent = {
  textInfoMessage: 'text.groupCategory.message',
};
